//@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap');
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/css/fonts/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/css/fonts/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/css/fonts/pxiGyp8kv8JHgFVrJJLucHtAOvWDSA.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/css/fonts/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/css/fonts/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2') format('woff2');
}

